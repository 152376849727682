<template>
  <div class="oilHeaderContainer">
    <div class="header">
      <div class="dateCard">
        <p class="date">{{ this.year }} {{ this.week }} {{ this.time }}</p>
        <p class="card">{{ headerInfo.device_name }}</p>
      </div>
      <p class="headerName">{{ headerInfo.name }}</p>
      <div class="logOut">
        <div class="in">
          <i class="el-icon-user-solid"></i>
          <span>Admin</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="out">
          <i class="el-icon-switch-button"></i><span>退出登陆</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OilHeader',
  props: {
    headerInfo: Object
  },
  data: () => {
    return {
      year: '',
      week: '',
      time: ''
    }
  },
  methods: {
    setDate() {
      this.year = this.$moment().format('LL')
      this.week = this.currentDay = this.$moment().format('dddd')
      this.time = this.$moment().format('a HH:mm')
    }
  },
  mounted() {
    this.setDate()
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';

.oilHeaderContainer {
  width: 100%;
  height: 83px;
  .header {
    padding: 0 46px 0 48px;
    width: 100%;
    height: inherit;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: url('https://pump7niu.ahsanen.com/img/common/header.png');
    background-size: cover;
    .dateCard {
      width: auto;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      .card {
        margin-left: 64px;
      }
    }
    .headerName {
      margin-right: 13%;
      margin-bottom: 0px;
      width: 360px;
      height: 36px;
      align-self: center;
      font-size: 36px;
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(0deg, #d7d7d7 0%, #ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .logOut {
      width: 130px;
      height: auto;
      align-self: flex-end;
      &:hover {
        .out {
          visibility: visible;
        }
      }
      .in {
        width: 130px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          color: #fff;
          font-size: 22px;
        }
        span {
          width: auto;
          font-size: 20px;
          font-family: SourceHanSansCN-Bold;
          color: #ffffff;
        }
      }
      .out {
        width: 130px;
        height: 40px;
        visibility: hidden;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #0a2541;
        i {
          color: rgba(255, 255, 255, 0.48);
          font-size: 22px;
        }
        span {
          width: auto;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.48);
        }
      }
    }
  }
}
</style>
