<template>
  <div class="inputModifyPopup" style="z-index: 2001" v-show="visible">
    <div class="mask"></div>
    <div class="popup">
      <div class="title">
        <p class="text">修改频率</p>
        <div class="exit" @click="hide"><i class="el-icon-close"></i></div>
      </div>
      <input
        class="up"
        type="text"
        v-model="val"
        placeholder="请输入上行频率"
      />
      <p class="tipsOne">{{ tips.one }}</p>
      <input
        class="down"
        type="text"
        v-model="val"
        placeholder="请输入上行频率"
      />
      <p class="tipsTwo">{{ tips.two }}</p>
      <button class="yes" type="button" @click="yes">确认</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputMOdifyPopup',
  data: () => {
    return {
      visible: false,
      tips: {
        one: '',
        two: ''
      },
      val: ''
    }
  },
  methods: {
    yes() {
      this.$emit('modifyFrencyque', 'modify')
      if (this.val == '') {
        this.tips = '密码不能为空'
        return false
      }
    },
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.inputModifyPopup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  .mask {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #606266;
    opacity: 0.7;
  }
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 20;
    width: 528px;
    height: 366px;
    background: url('https://pump7niu.ahsanen.com/img/common/input-popup-bg2.png');
    background-size: cover;
    .title {
      width: 100%;
      height: auto;
      .text {
        text-align: center;
        width: auto;
        height: 23px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #019ee5;
        line-height: 60px;
      }
      .exit {
        position: absolute;
        right: 47px;
        top: 12px;
        width: 30px;
        height: 30px;
        text-align: center;
        cursor: pointer;
        &:hover {
          i {
            color: #019ee5;
            transition: 0.3s ease;
          }
        }
        i {
          font-size: 22px;
          color: #046794;
        }
      }
    }
    .up,
    .down {
      position: absolute;
      padding-left: 23px;
      width: 424px;
      height: 52px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #019ee5;
      line-height: 60px;
      background: transparent;
      border: 1px solid #046794;
      &:focus {
        border-color: rgba(1, 158, 229, 1);
        transition: 0.3s ease;
      }
      &::placeholder {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: rgba(1, 158, 229, 1);
      }
    }
    .up {
      bottom: 195px;
      left: 52px;
    }
    .down {
      bottom: 115px;
      left: 52px;
    }
    .yes {
      position: absolute;
      bottom: 34px;
      left: 52px;
      width: 424px;
      height: 52px;
      line-height: 52px;
      letter-spacing: 2px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      border: none;
      background: #019ee5;
      &:focus {
        outline: 1px solid #046794;
      }
    }
    .tipsOne,
    .tipsTwo {
      width: auto;
      height: auto;
      position: absolute;
      font-size: 14px;
      font-family: Source Han Sans CN;
      color: red;
    }
    .tipsOne {
      bottom: 173px;
      left: 52px;
    }
    .tipsTwo {
      bottom: 93px;
      left: 52px;
    }
  }
}
</style>
