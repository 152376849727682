<template>
  <div class="page">
    <oil-header :headerInfo="headerInfo" @click="test"></oil-header>
    <input-pw-popup
      ref="pwPopup"
      @handleYesEvent="handleYesEvent"
    ></input-pw-popup>
    <input-modify-popup
      ref="mdPopup"
      @modifyFrencyque="modifyFrencyque"
    ></input-modify-popup>
    <div class="wrapper">
      <div class="leftFrame">
        <!-- 设备运行状态 -->
        <div class="machineState">
          <oil-title>
            <span slot="left">设备运行状态</span>
          </oil-title>
          <div class="msInfo">
            <div class="left">
              <div class="up">
                <p class="num">{{ monitorInfo.T_Production }}<span>m3</span></p>
                <p class="text">采油量</p>
              </div>
              <div class="down">
                <p class="text">耗电量</p>
                <p class="num">{{ monitorInfo.T_PowerC }}<span>h</span></p>
              </div>
            </div>
            <div class="middle">
              <el-progress
                type="circle"
                :width="progress.width"
                color="#00DAFC"
                :stroke-width="progress.stroke"
                :format="stateProgressFun"
                :percentage="progress.centage"
              ></el-progress>
            </div>
            <div class="right">
              <div class="up">
                <p class="num">{{ monitorInfo.T_Poweron_T }}<span>m3</span></p>
                <p class="text">上电时长</p>
              </div>
              <div class="down">
                <p class="iconU">
                  <i class="el-icon-top"></i> {{ monitorInfo.Uplink_F }}Hz
                </p>
                <p class="iconD">
                  <i class="el-icon-bottom"></i>
                  {{ monitorInfo.Downlink_F }}Hzhz
                </p>
                <p class="text">频率</p>
                <p class="num">188Hz</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 基本监控信息 -->
        <div class="machineMonitorInfo">
          <oil-title>
            <span slot="left">基本监控信息</span>
          </oil-title>
          <div class="infoList">
            <div class="listHeader">
              <div class="headerName">名称</div>
              <div class="headerValue">设备信息</div>
            </div>
            <div class="listBody">
              <div class="listItem">
                <div class="itemName">工作状态</div>
                <div class="itemValue">
                  {{ monitorInfo.Work_S | formatWorkS }}
                </div>
              </div>
              <div class="listItem">
                <div class="itemName">上行频率</div>
                <div class="itemValue">{{ monitorInfo.Uplink_F }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">下行频率</div>
                <div class="itemValue">{{ monitorInfo.Downlink_F }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">冲次</div>
                <div class="itemValue">{{ monitorInfo.Stroke_T }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">行程</div>
                <div class="itemValue">{{ monitorInfo.Route }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">散热器温度</div>
                <div class="itemValue">{{ monitorInfo.Radiator_T }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">控制柜温度</div>
                <div class="itemValue">{{ monitorInfo.Control_T }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">直流母线电流平均值</div>
                <div class="itemValue">{{ monitorInfo.DC_Main_Ia }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">直流电压平均值</div>
                <div class="itemValue">{{ monitorInfo.DC_Va }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">输入电压有效值</div>
                <div class="itemValue">{{ monitorInfo.Input_Ve }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">累计耗电量</div>
                <div class="itemValue">{{ monitorInfo.T_PowerC }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">累计采油量</div>
                <div class="itemValue">{{ monitorInfo.T_Production }}</div>
              </div>
              <div class="listItem">
                <div class="itemName">设备状态</div>
                <div class="itemValue">{{ monitorInfo.Device_State }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middleFrame">
        <!-- 中控设置 -->
        <div class="controlSettings">
          <div class="setLeft">
            <div class="setTips">
              <div class="tipsOn"></div>
              <p class="tipsText">开</p>
              <div class="tipsOff"></div>
              <p class="tipsText">关</p>
            </div>
            <div class="setList">
              <div class="icon-mainG" :style="monitorInfo.Main_G | mainGState">
                <span>主开关状态</span>
              </div>
              <div
                class="icon-precharge"
                :style="monitorInfo.Precharge_G | prechargeState"
              >
                <span>预充电开关状态</span>
              </div>
              <div class="icon-fanG" :style="monitorInfo.Fan_G | fanGState">
                <span>风机开关状态</span>
              </div>
            </div>
          </div>
          <div class="setRight">
            <div class="setList">
              <div class="icon-start setOffColor" @click="start">启动</div>
              <div class="icon-stop setOffColor">停止</div>
              <div class="icon-up setOffColor">上行</div>
              <div class="icon-down setOffColor">下行</div>
              <div class="icon-f setOffColor" @click="frencyque">频率</div>
            </div>
            <div class="oilBg"></div>
          </div>
          <div class="refresh" @click="refresh">
            <div class="opt"></div>
            <div class="text">刷新数据</div>
          </div>
        </div>
        <!-- 中控数据可视化图 -->
        <div class="controlCharts">
          <div class="left">
            <div class="title">
              <p>故障报警信息</p>
            </div>
            <div class="alarmList">
              <div class="listHeader">
                <div class="headerName">名称</div>
                <div class="headerTime">时间</div>
                <div class="headerValue">风险预警</div>
              </div>
              <div class="listBody">
                <div class="listItem" v-for="item in alarmInfo" :key="item.id">
                  <div class="itemName">{{ item.faultname }}</div>
                  <div class="itemTime">{{ item.time }}</div>
                  <div class="itemValue" :style="item.state | formateState">
                    {{ item.state }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="middle"></div>
          <div class="right">
            <div class="title">
              <p class="tit">历史采油量及能耗</p>
              <p class="unit">采油量单位：立方米<span>能耗单位：MW.h</span></p>
            </div>
            <history-oil-prod-chart
              v-if="flag.historyProdPower"
              :historyInfo="historyProdPowerInfo"
            ></history-oil-prod-chart>
          </div>
        </div>
      </div>
      <div class="rightFrame">
        <!-- 设备运行监控 -->
        <oil-title>
          <span slot="left">设备运行监控</span>
        </oil-title>
        <oil-line-chart
          v-if="flag.oilEleWell"
          :lineChartTheme="oilElectric"
          :historyInfo="oilElectricInfo"
        ></oil-line-chart>
        <oil-line-chart
          v-if="flag.oilEleWell"
          :lineChartTheme="oilWell"
          :historyInfo="oilWellInfo"
        ></oil-line-chart>
        <!-- 三日天气预报 -->
        <div class="threeWeather">
          <oil-title>
            <span slot="left">三日天气预报</span>
          </oil-title>
          <div class="weatherList" @click="test">
            <div
              class="listItem"
              v-for="(item, index) in weatherInfo"
              :key="index"
            >
              <img class="itemIcon" :src="item.weather | formatWeather" />
              <div class="itemDate">
                <p class="date">{{ item.week | formatWeek }} {{ item.date }}</p>
                <p class="level">{{ item.wind }}风{{ item.power }}级</p>
              </div>
              <div class="itemValue">
                {{ item.daytemp }}℃~{{ item.nighttemp }}℃
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OilHeader from 'components/oilHeader/OilHeader.vue'
import OilTitle from 'components/oilTitle/OilTitle.vue'
import HistoryOilProdChart from 'components/oilBarChart/HistoryOilProdChart.vue'
import OilLineChart from 'components/oilLineChart/OilLineChart.vue'
import InputPwPopup from 'components/popup/InputPwPopup.vue'
import InputModifyPopup from 'components/popup/InputModifyPopup.vue'

import commonJS from 'assets/js/common.js'
import singleMachineJS from 'network/machine/singleMachine.js'

export default {
  name: 'SingleMachine',
  components: {
    OilHeader,
    OilTitle,
    HistoryOilProdChart,
    OilLineChart,
    InputPwPopup,
    InputModifyPopup
  },
  data() {
    return {
      headerInfo: {
        name: '智慧抽油监控系统',
        device_name: ''
      },
      progress: {
        width: 102,
        stroke: 9,
        centage: 80
      },
      mainG: {
        false:
          'https://pump7niu.ahsanen.com/img/singleMachine/icon-mainG-false.png',
        true: 'https://pump7niu.ahsanen.com/img/singleMachine/icon-mainG-true.png'
      },
      monitorTimer: null,
      alarmTimer: null,
      historyTimer: null,
      timer: {
        start: null,
        fiveS: null,
        sixH: null
      },
      params: {},
      flag: { historyProdPower: false, oilEleWell: false },
      monitorInfo: {},
      alarmInfo: [],
      historyProdPowerInfo: {},
      oilElectricInfo: {},
      oilWellInfo: {},
      weatherInfo: [],
      oilElectric: {
        legend: ['电流', '电压'],
        series: [
          {
            itemColor: '#55CB58', // 折点颜色
            lineColor: '#55CB58', // 折线颜色
            areaColor: 'RGBA(0, 100, 150, 1)' // 区域颜色
          },
          {
            itemColor: '#55CB58',
            lineColor: '#55CB58',
            areaColor: 'RGBA(0, 100, 150, 1)'
          }
        ]
      },
      oilWell: {
        legend: ['井下压力', '井下温度'],
        series: [
          {
            itemColor: '#55CB58', // 折点颜色
            lineColor: '#55CB58', // 折线颜色
            areaColor: 'RGBA(0, 100, 150, 1)' // 区域颜色
          },
          {
            itemColor: '#F26163',
            lineColor: '#F26163',
            areaColor: 'rgba(242, 97, 99, 0.2)'
          }
        ]
      }
    }
  },
  methods: {
    /**
     *  事件监听
     */
    test() {},
    handleYesEvent(v) {
      console.log('v', v)
      switch (v) {
        case 'start':
          this.sendYesEvenTest()
          break
        case 'frencyque':
          this.$refs.mdPopup.show()
          break
      }
    },
    start() {
      this.$refs.pwPopup.opt = 'start'
      this.$refs.pwPopup.show()
    },
    frencyque() {
      this.$refs.pwPopup.opt = 'frencyque'
      this.$refs.pwPopup.show()
    },
    // 密码确认后弹起频率修改框
    modifyFrencyque(v) {
      console.log('v', v)
    },
    // 刷新数据
    refresh() {
      this.getDeviceMonitor()
      this.getDeviceAlarm()
      this.getDeviceHistoryIaVaDownhole()
      this.getDeviceHistoryProdPower()
      this.getDeviceWeather()
    },
    /**
     *  网络请求
     */
    // 修改操作发送网络请求
    sendYesEvenTest() {
      console.log('yes send')
    },
    // 定时获取5s
    fiveSecondsTimer() {
      this.timer.fiveS = setInterval(() => {
        this.getDeviceMonitor()
        this.getDeviceAlarm()
        this.getDeviceHistoryIaVaDownhole()
      }, 1000 * 5)
    },
    sixHoursTimer() {
      this.timer.sixH = setInterval(() => {
        this.getDeviceHistoryProdPower()
        this.getDeviceWeather()
      }, 1000 * 60 * 60 * 6)
    },
    // 获取基本监控信息
    getDeviceMonitor() {
      singleMachineJS.deviceMonitor(this.params).then((res) => {
        if (res.code == 0) {
          this.monitorInfo = res.monitor_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取故障报警信息
    getDeviceAlarm() {
      singleMachineJS.deviceAlarm(this.params).then((res) => {
        if (res.code == 0) {
          this.alarmInfo = res.alarm_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取历史采油量及能耗
    getDeviceHistoryProdPower() {
      singleMachineJS.deviceHistoryProdPower(this.params).then((res) => {
        if (res.code == 0) {
          this.flag.historyProdPower = true
          this.historyProdPowerInfo = res.history_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取设备运行运行监控
    getDeviceHistoryIaVaDownhole() {
      singleMachineJS.deviceHistoryIaVaDownhole(this.params).then((res) => {
        if (res.code == 0) {
          this.flag.oilEleWell = true
          this.oilElectricInfo = {
            time: res.history_info.Time,
            yFirstData: res.history_info.DC_Main_Ia,
            ySecondData: res.history_info.DC_Va
          }
          this.oilWellInfo = {
            time: res.history_info.Time,
            yFirstData: res.history_info.Downhole_P,
            ySecondData: res.history_info.Downhole_T
          }
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取三日天气
    getDeviceWeather() {
      singleMachineJS.deviceWeather(this.params).then((res) => {
        if (res.code == 0) {
          this.weatherInfo = res.weather_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    /**
     *  其它方法
     */

    // 指定进度条文字内容
    stateProgressFun(percentage) {
      return percentage + '°C' + '\n' + '温度'
    }
  },
  filters: {
    // 工作状态转换
    formatWorkS(value) {
      let str = ''
      switch (value) {
        case 1:
          str = '上行状态'
          break
        case 2:
          str = '等待状态'
          break
        case 4:
          str = '下行状态'
          break
        case 8:
          str = '结束状态'
          break
        default:
      }
      return str
    },
    // 星期转换
    formatWeek(value) {
      const arr = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      return arr[value - 1]
    },
    // 天气转换
    formatWeather(value) {
      return commonJS.formatWeather(value)
    },
    // 故障状态转换
    formateState(value) {
      return value == '故障' ? { color: '#FF3232' } : { color: '#27e54a' }
    },
    // 故障状态转换
    mainGState(value) {
      const f =
        'https://pump7niu.ahsanen.com/img/singleMachine/icon-mainG-false.png'
      const t =
        'https://pump7niu.ahsanen.com/img/singleMachine/icon-mainG-true.png'
      return value == 0
        ? { background: 'url(' + f + ')' }
        : { background: 'url(' + t + ')' }
    },
    prechargeState(value) {
      const f =
        'https://pump7niu.ahsanen.com/img/singleMachine/icon-precharge-false.png'
      const t =
        'https://pump7niu.ahsanen.com/img/singleMachine/icon-precharge-true.png'
      return value == 0
        ? { background: 'url(' + f + ')' }
        : { background: 'url(' + t + ')' }
    },
    fanGState(value) {
      const f =
        'https://pump7niu.ahsanen.com/img/singleMachine/icon-fanG-false.png'
      const t =
        'https://pump7niu.ahsanen.com/img/singleMachine/icon-fanG-true.png'
      return value == 0
        ? { background: 'url(' + f + ')' }
        : { background: 'url(' + t + ')' }
    }
  },
  mounted() {
    this.getDeviceMonitor()
    this.getDeviceAlarm()
    this.getDeviceHistoryProdPower()
    this.getDeviceHistoryIaVaDownhole()
    this.getDeviceWeather()
    // 定时器
    this.timer.start = setInterval(() => {
      if (this.flag.historyProdPower && this.flag.oilEleWell) {
        clearInterval(this.timer.start)
        this.timer.start = null
        this.fiveSecondsTimer()
        this.sixHoursTimer()
      }
    }, 300)
  },
  created() {
    this.headerInfo.device_name = this.$route.query.device_name
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
    this.params = {
      session_id: this.$store.state.loginInfo.session_id,
      device_name: this.$route.query.device_name
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer.fiveS)
    window.clearInterval(this.timer.sixH)
    this.timer.fiveS = null
    this.timer.sixH = null
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.setOffColor {
  color: #019ee5;
}
.setOnColor {
  color: #a5e3ff;
}

.page {
  width: 100%;
  height: 100%;
  background: url('https://pump7niu.ahsanen.com/img/common/bg.png');
  background-size: cover;
  .wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
  }
  .leftFrame {
    margin: 19px 0px 25px 19px;
    width: 386px;
    height: auto;
    .machineState {
      width: inherit;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .msInfo {
        margin-top: 26px;
        width: 358px;
        height: 211px;
        background: url('https://pump7niu.ahsanen.com/img/singleMachine/state-bg.png');
        background-size: cover;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        .left,
        .right {
          width: 97px;
          height: 231px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .up,
          .down {
            width: 194px;
            height: 49%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .num {
              width: auto;
              height: 26px;
              font-size: 26px;
              font-family: SourceHanSansCN-Bold;
              font-weight: bold;
              font-style: italic;
              color: #ffffff;
              span {
                font-size: 14px;
              }
            }
            .text {
              width: auto;
              height: 12px;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
        .left,
        .right {
          .up {
            .text {
              margin-top: 13px;
            }
          }
          .down {
            .num {
              margin-top: 13px;
            }
          }
        }
        .right {
          .down {
            position: relative;
            text-align: right;
            .iconU {
              position: absolute;
              left: 27px;
              bottom: 41px;
              width: auto;
              height: 12px;
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #32edaa;
              i {
                font-weight: bold;
              }
            }
            .iconD {
              position: absolute;
              left: 0px;
              bottom: 21px;
              width: auto;
              height: 11px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #f63939;
              i {
                font-weight: bold;
              }
            }
          }
        }
        .middle {
          width: 164px;
          height: 163px;
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep {
            .el-progress__text {
              margin-left: 4px;
              width: 90px;
              height: 90px;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: pre;
              text-align: center;
              font-size: 18px !important;
              font-family: SourceHanSansCN-Bold;
              font-weight: bold;
              font-style: italic;
              color: #ffffff;
            }
            svg path:first-child {
              stroke: transparent;
            }
          }
        }
      }
    }
    .machineMonitorInfo {
      margin-top: 26px;
      width: inherit;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .infoList {
        margin-top: 14px;
        width: 380px;
        height: 588px;
        background: rgba(29, 84, 128, 0.5);
        .listHeader {
          width: 380px;
          height: 42px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          background: rgba(29, 84, 128, 0.5);
          .headerName,
          .headerValue {
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;
          }
          .headerName {
            margin-left: 25px;
            width: 34%;
          }
          .headerValue {
            width: 38%;
          }
        }
        .listBody {
          width: inherit;
          height: 546px;
          .listItem:nth-child(odd) {
            background: rgba(1, 24, 49, 1);
          }
          .listItem:nth-child(even) {
            background: rgba(4, 40, 72, 0.8);
          }
          .listItem {
            width: inherit;
            height: 42px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .itemName {
              margin-left: 25px;
              width: 38%;
              height: 16px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #dedede;
            }
            .itemValue {
              width: 38%;
              height: 16px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .middleFrame {
    margin: 35px 13px 25px 13px;
    width: 1076px;
    height: auto;
    display: flex;
    flex-direction: column;

    .controlSettings {
      position: relative;
      width: inherit;
      height: 580px;
      display: flex;
      .setLeft {
        margin: 90px 0 0 27px;
        width: 150px;
        height: auto;
        .setTips {
          width: 150px;
          height: 8px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-evenly;
          align-items: center;
          .tipsOn {
            width: 8px;
            height: 8px;
            background: #e7ac23;
            border-radius: 50%;
          }
          .tipsOff {
            width: 8px;
            height: 8px;
            background: #019ee5;
            border-radius: 50%;
          }
          .tipsText {
            width: auto;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .setList {
          margin-top: 20px;
          width: 150px;
          height: 336px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .icon-mainG,
          .icon-precharge,
          .icon-fanG {
            padding-bottom: 18px;
            width: 150px;
            height: 96px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-size: cover;
            span {
              width: auto;
              height: 16px;
              text-align: center;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #fff;
            }
          }
          .icon-mainG {
          }
          .icon-precharge {
          }
          .icon-fanG {
          }
        }
      }
      .setRight {
        width: 835px;
        height: 597px;
        .setList {
          margin-left: 3px;
          width: inherit;
          height: 79px;
          display: flex;
          flex-flow: row nowrap;
          .icon-start,
          .icon-stop,
          .icon-up,
          .icon-down,
          .icon-f {
            margin-left: 17px;
            padding-bottom: 17px;
            width: 131px;
            height: 79px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-size: cover;
            cursor: pointer;
          }
          .icon-start {
            background: url('https://pump7niu.ahsanen.com/img/singleMachine/icon-start-false.png');
          }
          .icon-stop {
            background: url('https://pump7niu.ahsanen.com/img/singleMachine/icon-stop-false.png');
          }
          .icon-up {
            background: url('https://pump7niu.ahsanen.com/img/singleMachine/icon-up-false.png');
          }
          .icon-down {
            background: url('https://pump7niu.ahsanen.com/img/singleMachine/icon-down-false.png');
          }
          .icon-f {
            background: url('https://pump7niu.ahsanen.com/img/singleMachine/icon-f-false.png');
          }
        }
        .oilBg {
          margin-top: 20px;
          width: 835px;
          height: 482px;
          background: url('https://pump7niu.ahsanen.com/img/singleMachine/oil-bg.png');
          background-size: cover;
        }
      }
      .refresh {
        position: absolute;
        bottom: 31px;
        right: 0px;
        width: auto;
        height: 49px;
        display: flex;
        align-items: center;
        .opt {
          width: 45px;
          height: 45px;
          background: url('https://pump7niu.ahsanen.com/img/singleMachine/refresh-btn.png');
          background-size: cover;
        }
        .text {
          width: auto;
          height: 16px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .controlCharts {
      width: 1076px;
      height: auto;
      display: flex;
      .oilBarChart {
        margin: 12px 0 0 7px;
      }
      .left {
        margin-left: 14px;
        width: 382px;
        height: 357px;
        .title {
          width: 382px;
          height: 42px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          letter-spacing: 1px;
          background: url('https://pump7niu.ahsanen.com/img/singleMachine/header-bg.png');
          background-size: cover;
          p {
            margin-left: 69px;
            width: auto;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f9fafa;
            line-height: 11px;
          }
        }
        .alarmList {
          margin-top: 12px;
          width: 376px;
          height: 303px;
          background: #011831;
          .listHeader {
            width: 376px;
            height: 36px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            background: rgba(29, 84, 128, 0.5);
            .headerName,
            .headerTime {
              height: 14px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
            .headerValue {
              height: 14px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffc600;
            }
            .headerName {
              margin-left: 18px;
              width: 35%;
            }
            .headerTime {
              width: 27%;
            }
            .headerValue {
              margin-right: 14px;
              width: 16.5%;
            }
          }
          .listBody {
            width: 376px;
            height: 267px;
            .listItem:nth-child(odd) {
              background: rgba(1, 24, 49, 1);
            }
            .listItem:nth-child(even) {
              background: rgba(4, 40, 72, 0.8);
            }
            .listItem {
              width: 376px;
              height: 38px;
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              align-items: center;
              .itemName,
              .itemTime {
                height: 12px;
                font-size: 12px;
                text-align: left;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #7bbcee;
              }
              .itemName {
                margin-left: 18px;
                width: 35%;
              }
              .itemTime {
                width: 32%;
              }
              .itemValue {
                margin-right: 14px;
                width: 15%;
                text-align: left;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }
      }
      .middle {
        width: 31px;
        height: 357px;
        background: url('https://pump7niu.ahsanen.com/img/singleMachine/oil-bg2.png');
        background-size: cover;
      }
      .right {
        width: 656px;
        height: 357px;
        .historyOilProdChart {
          margin-top: 10px;
        }
        .title {
          width: 656px;
          height: 43px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          letter-spacing: 1px;
          background: url('https://pump7niu.ahsanen.com/img/singleMachine/header-bg2.png');
          background-size: cover;
          .tit {
            margin-left: 69px;
            width: auto;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f9fafa;
            line-height: 11px;
          }
          .unit {
            width: auto;
            height: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(249, 250, 250, 0.64);
            line-height: 11px;
            span {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .rightFrame {
    margin: 22px 0px 0 9px;
    width: 386px;
    height: auto;
    .threeWeather {
      margin-top: 21px;
      width: 386px;
      height: 357px;
      .weatherList {
        margin-top: 11px;
        width: 386px;
        height: 303px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .listItem {
          width: 380px;
          height: 88px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          background: rgba(23, 78, 128, 0.22);
          .itemIcon {
            margin-left: 18px;
            width: auto;
            height: auto;
          }
          .itemDate {
            min-width: 120px;
            height: 56px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          .itemValue {
            margin-right: 18px;
            width: 122px;
            height: 24px;
            width: 122px;
            font-size: 24px;
            font-family: SourceHanSansCN-Bold;
            color: #00b0ff;
          }
        }
      }
    }
  }
}
</style>
