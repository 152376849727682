<template>
  <div class="oilTitleContainer">
    <div class="title">
      <p class="left">
        <el-tooltip
          class="item"
          effect="dark"
          :content="tipTitle"
          placement="top-start"
        >
          <span class="tit"><slot name="tit"></slot></span> </el-tooltip
        ><slot name="left"></slot>
      </p>
      <p class="right"><slot name="right"></slot></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OilTitle',
  props: {
    tipTitle: String
  },
  data: () => {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';

.oilTitleContainer {
  width: 386px;
  height: 43px;
  > .title {
    width: inherit;
    height: inherit;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    background: url('https://pump7niu.ahsanen.com/img/common/title.png');
    background-size: cover;
    .left {
      margin-left: 69px;
      width: auto;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #f9fafa;
      line-height: 11px;
    }
    .right {
      width: auto;
      height: 16px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(249, 250, 250, 0.64);
      line-height: 11px;
    }
  }
}
</style>
